<template>
  <div>
    <div class="modal fade" data-backdrop="static" data-keyboard="false" id="VPI-score-modal" tabindex="-2" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <form class="modal-content" @submit.prevent="">
          <div class="modal-header">
            <div class="modal-title w-100 text-center">
              <h5 class="font-weight-bold">VPI SCORE</h5>
            </div>
          </div>
          <div class="modal-body">
            <template v-if="editScores">
              <!--            For Certificates/Records -->
              <div class="form-group row no-gutters mb-1">
                <label for="audit_type_1" class="font-weight-bold col-sm-9 col-form-label">PSC SCORE</label>
                <span class="col-sm-3 form-control form-control-sm text-center">
                  {{ psc_score }}
                </span>
              </div>
              <!--            For Safety of Navigation audit -->
              <div class="form-group row no-gutters mb-1">
                <label for="audit_type_1" class="font-weight-bold col-sm-9 col-form-label">INTERNAL AUDIT SCORE</label>
                <div class="col-sm-3">
                  <input type="text" id = "internal_audit_score_input" class="form-control form-control-sm text-center"
                         autocomplete="off"
                         v-model="scores.internal_audit_score"
                         @input="scores.internal_audit_score = setLimitValue(scores.internal_audit_score)"
                         @keyup.up="scores.internal_audit_score = addValue(scores.internal_audit_score, 0.01)"
                         @keyup.down="scores.internal_audit_score = subtractValue(scores.internal_audit_score, 0.01)"
                         @keydown.up="moveCursorToEnd"
                         @keyup.enter="update"
                  >
                </div>
              </div>
              <!--            For Bridge Safety Equipment Maintenance/SEMA -->
              <div class="form-group row no-gutters mb-1">
                <label for="audit_type_1" class="font-weight-bold col-sm-9 col-form-label">ICBT SCORE</label>
                <span class="col-sm-3 form-control form-control-sm text-center">
                {{ icbt_score ?? 0 }}
              </span>
              </div>
            </template>
            <template v-else>
              <!--            For Certificates/Records -->
              <div class="form-group row no-gutters mb-1">
                <label for="audit_type_1" class="font-weight-bold col-sm-9 col-form-label">PSC SCORE</label>
                <span class="col-sm-3 form-control form-control-sm text-center">
                      <app-loading
                        v-if="loading"
                        :spinner-only="true"
                        :height="'12px'"
                        :width="'12px'"
                      />
                      <template v-else>
                           {{ parseFloat(psc_score) ?? 0}}
                      </template>


                </span>

              </div>
              <!--            For Safety of Navigation audit -->
              <div class="form-group row no-gutters mb-1">
                <label for="audit_type_1" class="font-weight-bold col-sm-9 col-form-label">INTERNAL AUDIT SCORE</label>
                <span class="col-sm-3 form-control form-control-sm text-center">
                      <app-loading
                        v-if="loading"
                        :spinner-only="true"
                        :height="'12px'"
                        :width="'12px'"
                      />
                      <template v-else>
                        {{ parseFloat(internal_audit_score) ?? 5.0}}
                    </template>
              </span>
              </div>
              <!--            For Bridge Safety Equipment Maintenance/SEMA -->
              <div class="form-group row no-gutters mb-1">
                <label for="audit_type_1" class="font-weight-bold col-sm-9 col-form-label">ICBT SCORE</label>
                <span class="col-sm-3 form-control form-control-sm text-center">
                    <app-loading
                      v-if="loading"
                      :spinner-only="true"
                      :height="'12px'"
                      :width="'12px'"
                    />
                      <template v-else>
                        {{ parseFloat(icbt_score) ?? 5.0 }}
                      </template>
              </span>
              </div>
            </template>
            <!--            For Average score -->
            <div class="form-group row no-gutters mb-1">
              <label for="audit_type_1" class="font-weight-bold col-sm-9 col-form-label">Average score</label>
              <span class="col-sm-3 form-control form-control-sm text-center e-bg-red text-white">
                {{ parseFloat(averageScore) ?? 5.0 }}
              </span>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-secondary" @click="close">Close</button>
            <template v-if="editScores">
              <button class="btn btn-sm e-btn-outlined-red" type="button" @click="cancel">Cancel</button>
              <button type="button" class="btn btn-sm btn-primary" @click="update">Save</button>
            </template>
            <button class="btn btn-sm e-btn-green" type="button" v-else @click="edit" :disabled="loading" :class="[loading === true ? 'cursor-not-allowed' : '']">Edit</button>
          </div>
        </form>
      </div>
    </div>
  </div>

</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/services/AlertService";
import VpiMixin from "@/mixins/VpiMixin";
import AppLoading from "@/components/elements/AppLoading.vue";

export default {
  name: "VPIScore",
  components: {AppLoading},
  mixins : [VpiMixin],
  data() {
    return {
      editScores : false,
      scores : {
        psc_score : 0,
        internal_audit_score : 0,
        icbt_score : 0,
      },
      vpiFilter : {
        year : new Date().getFullYear(),
        vessel_id : this.$route.params.vessel_id,
        for_list : false
      },
      vesselVpiScores : null,
      psc_score : null,
      icbt_score: null,
      auditMonth : null,
      loading : false
    }
  },
  props : {
    id : {
      type : Number,
      default : 0
    },
    internal_audit_score : {
      type : Number | String,
      default : 0
    }
  },
  watch : {
    // scores: {
    //   handler() {
    //     for (let key in this.scores) {
    //       if (this.scores[key].toString().trim() === '') {
    //         this.scores[key] = 0;
    //       } else {
    //         if (this.scores[key].toString().length > 1 && this.scores[key].toString()[0] === '0') {
    //           this.scores[key] = this.scores[key].substring(1);
    //         }
    //       }
    //     }
    //   },
    //   deep: true
    // },
  },
  methods : {
    ...mapActions([
      'getInternalAudit',
      'updateInternalAuditReport',
      'getVesselsVpi',
      'getVesselAuditRecordById',
    ]),
    async close() {
      await $('#VPI-score-modal').modal('hide');
      this.editScores = false;
      this.$emit('close');
    },
    edit() {
      this.editScores = true;
      setTimeout(() => {
        $('#internal_audit_score_input').focus();
      }, 100)
    },
    cancel() {
      this.editScores = false;
      this.setScores();
    },
    async update() {
      let paramForm = new FormData();
      paramForm.append('id', this.id);
      paramForm.append('internal_audit_score', this.scores.internal_audit_score);

      const updateResponse = await this.updateInternalAuditReport({
        id : this.id,
        form : paramForm
      });

      if (updateResponse.result === false) {
        return AlertService.errorAlert(updateResponse.message, 'UPDATING OF VPI SCORES');
      }

      AlertService.successAlert('Updated successfully', 'UPDATING OF VPI SCORES');
      this.editScores = false;

      await this.getVesselAuditRecordById(this.$route.params.id);

      await this.close();
    },
    setScores() {
      this.scores.internal_audit_score = this.internal_audit_score;
      if (this.vesselVpiScores.length === 1) {
        this.auditMonth = new Date(this.selectedAuditRecord[this.$route.params.type].audit_date_end['date-picker']).toLocaleString('en-US', { month: 'long' }).toLowerCase();
        this.psc_score = this.vesselVpiScores[0][this.vpiFilter.year].psc[this.auditMonth].average_score === '' ? 5 : this.psc_score = this.vesselVpiScores[0][this.vpiFilter.year].psc[this.auditMonth].average_score;
        this.icbt_score = this.vesselVpiScores[0][this.vpiFilter.year].icbt[this.auditMonth].average_score === '' ? 5 : this.vesselVpiScores[0][this.vpiFilter.year].icbt[this.auditMonth].average_score;
        this.scores.psc_score = this.psc_score;
        this.scores.icbt_score = this.icbt_score;
        this.scores.internal_audit_score = this.internal_audit_score;
      }
    },
    setVpiFilter() {
      this.vpiFilter.year = new Date(this.selectedAuditRecord[this.$route.params.type].audit_date_end['date-picker']).getFullYear();
    },
  },
  computed : {
    ...mapGetters([
      'selectedAuditRecord'
    ]),
    averageScore() {
      let average = 0;
      for (const vip_key in this.scores) {
        average +=  this.scores[vip_key] === '' || this.scores[vip_key] === null ? 0 : parseFloat(this.scores[vip_key]);
      }
      return _.round((average / 3), 2);
    },
  },
  async created() {
    this.loading = true;
    await this.setVpiFilter();
    this.vesselVpiScores = await this.getVesselsVpi(this.vpiFilter);
    await this.setScores();
    this.$emit('setPscAndIcbtScore', {
      psc_score : this.psc_score,
      icbt_score : this.icbt_score,
      averageScore : this.averageScore
    });
    this.loading = false;
  }
}
</script>

<style scoped>

</style>
